import { Grid, useTheme, useMediaQuery, FormControl, InputLabel, OutlinedInput, Button, TextField } from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";

function ContactForm() {
    const theme = useTheme();
    const isMedScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [state, handleSubmit] = useForm("xwpkeqka");
    const [emailValid, setEmailValid] = useState(true);
    const [messageValid, setMessageValid] = useState(true);

    const validateThenSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailValid(emailValid);

        const message = formData.get("message") as string;
        const messageValid = message.length > 10;
        setMessageValid(messageValid);

        if (emailValid && messageValid) {
            handleSubmit(e);
        }
    };

    const form = (!state.succeeded) ? (
        <form onSubmit={validateThenSubmit}>
          <FormControl fullWidth style={{paddingBottom: "10px"}}>
              <TextField error={!emailValid} id="email" name="email" label="Email" helperText={(emailValid) ? "" : "Invalid email"} onChange={(e) => setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value))} />
          </FormControl>
          <FormControl fullWidth style={{paddingBottom: "10px"}}>
              <TextField error={!messageValid} multiline id="message" name="message" label="Message" helperText={(messageValid) ? "" : "Message must be at least 10 characters"} rows={6} onChange={(e) => setMessageValid(e.target.value.length > 10)} />
          </FormControl>
          <FormControl fullWidth style={{paddingBottom: "10px"}}>
              <Button variant="outlined" type="submit" disabled={state.submitting}>Submit</Button>
          </FormControl>
        </form>
      ) : (
        <h3 style={{textAlign: "center"}}>Thank you for your message. We will response as quickly as we can.</h3>
      );
    
    return (
        <Grid container sx={{marginBottom: "40px"}}>
            <Grid item xs={0} md={2} sx={{}}></Grid>
            <Grid item xs={12} md={8} sx={{ background: "#404040", borderRadius: (isMedScreen) ? "0px" : "10px", padding: "20px" }}>
            <h2 style={{textAlign: "center"}}>Contact us for pricing, offers, trades and more info.</h2>
            {form}
            </Grid>
            <Grid item xs={0} md={2} sx={{}}></Grid>
        </Grid>
        );
}

export default ContactForm;