import { CSSProperties, useContext, useEffect, useState } from "react";
import ImageUploaderItem from "./ImageUploaderItem";

interface ImageUploaderProps {
    onDelete: (index: number) => void;
    onReorder: (index: number, direction: number) => void;
    filesPicked: (data: any) => void;
    images:string[];
    style?: CSSProperties;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({onDelete, onReorder, filesPicked, images, style}) =>{
    const picked = async (evt:any) => {
        if (evt.target.files) {
            for (var i = 0; i < evt.target.files.length; i++) {
                let index = i;
                const fileData = await readFile(evt.target.files, index);
                filesPicked(fileData);
            }
        }
    };

    // reads the file as a base64 encoded string
    const readFile = (files:any, index:number) => {
        return new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve({ photo: reader.result, index: index, name: files[index].name, file: files[index]});
            }
            reader.readAsDataURL(files[index]);
        });
    };

    let imgs = images.map((img:string, index:number) => {
        console.log(img);
        let disabled = (img.indexOf("https://") === -1);
        return (<ImageUploaderItem key={img} image={img} onDelete={onDelete} onReorder={onReorder} index={index} size={images.length} disabled={disabled} />);
    });
    return (
        <div className="img-uploader" style={style}>
            <h3>Images</h3>
            <div className="img-uploader-container">
                {imgs}
                <div className="img-uploader-picker">
                    <input type="file" className="img-uploader-file" onChange={picked} accept="image/x-png,image/jpeg,image/webp" multiple />
                </div>
            </div>
        </div>
    );
}

export default ImageUploader;