import { useContext, useEffect, useState } from "react";
import ContactForm from "../general/ConactForm";

function SaleTerms() {
  return (
    <div>
      <section className={"section-hero parallax--bg saleterms"}>
          <div className="container">
              <h1 className="parallax--box">CONSIGNMENTS & SALES</h1>
              <p>Get your amazing gear out there.</p>
          </div>
      </section>
      <div className="container2">
        <h2>IN THE HAND OF EXPERTS</h2>
        <p>We&apos;ve been doing this for a long while now, helping small or bigger collectors, Guitar Stores or musicians, to sell, buy and trade particular guitars. We will follow for you all the process that is needed to source or sell your dream&apos;s guitar.</p>
        <h2>TERMS & CONDITIONS</h2>
        <h4>CONTACT US:</h4>
        <p>Please ALWAYS email us first to know if the item is still available, and to receive detailed instrument description and complete photo-set of the guitar.</p>
        <h4>PAYMENTS:</h4>
        <p>For instruments / amps / gear purchases you can pay by bank wire transfer. Please instruct your bank that all banking charges must be on your account. We&apos;ll send you by email invoice and our banking data, or the direct data of the guitar&apos;s owner, in case of guitars on consignment. We do have Paypal that can be used to put a HOLD on a guitar or in particular cases, after common Agreement between seller and buyer.</p>
        <h4>CONSIGNMENT SALES:</h4>
        <p>Our sales services and appraisals will be charged depending on the instrument&apos;s value, with full Agreement with owner, to a maximum of 10% of the final sales amount.</p>
        <h4>HOLD:</h4>
        <p>A 25% non refundable deposit will hold any instrument for a maximum of 60 days! The item will be shipped the same day full payment is received. No approval on held instruments.</p>
        <h4>SHIPPING:</h4>
        <p>We ship with UPS Express or other carrier of your choice, such as FedEx or DHL. Buyer pays all shipping, insurance and customs taxes. Any insurance claims are responsability of the buyer. Items are shipped at customer&apos;s risk. We are not responsible for loss or damage. Local Pickup is Always welcome.</p>
        <h4>24 hour approval on all guitars and basses:</h4>
        <p>All items can be returned if you find that they differ from the description we have given to you. For a full refund, merchandise must be returned, fully insured, in the same condition it was sent. Refund is limited to the selling price of the item, and will be sent by bank wire only after we receive back the item. No approval and no return on items purchased to order (on commission). No approval and no return on items kept on hold.</p>
        <h4>Trade-ins and purchase of your fine vintage instruments:</h4>
        <p>we&apos;re Always open to evaluate your proposal, being it for a straight trade deal with a guitar per other guitar or more articulate trades with more instruments. In these cases, your offered guitars must be inspected by us with a in-hand Appraisal, you will have to ship them here at your charge and risk, or you&apos;re welcome to bring them here in person. Final evaluation of the guitars will be made after detailed Appraisal and final Agreement with buyer.</p>
      </div>
      <ContactForm />
    </div>
  );
}

export default SaleTerms;