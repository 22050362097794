import { Grid, useTheme, useMediaQuery, Box, Typography } from "@mui/material";
import {Helmet} from "react-helmet-async";
import { useState } from "react";
import "./Home.css";

interface HomeProps {
    onWait: (wait: boolean) => void;
}

const Home: React.FC<HomeProps> = ({onWait}) =>{
	const theme = useTheme();
  	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [wait, setWait] = useState<any>();

	const items = [
		{ img: "/img/about.png", title: "About", description: "CescosCornerGuitars was established in 2012 with the aim to build a safe place for musician and collectors to buy, sell, and educate themselves on Vintage Guitars. We've authenticated, restored, and helped sell hundreds of amazing vintage guitars and work to share their history and our knowledge with you.", link: "/about", linkText: "Learn more about CescosCornerGuitars." },
		{ img: "/img/inventory.png", title: "Inventory", description: "We have an amazing and frequently changing inventory of vintage instruments available for purchase. All instruments are inspected, authenticated, and setup to play many more years. We are happy to provide additional information and detailed pictures upon request...just drop us a note.", link: "/inventory", linkText: "Explore our inventory." },
		{ img: "/img/portfolio.png", title: "Portfolio", description: "CescosCornerGuitars keeps a detailed portfolio of instruments that has crossed our bench. Sharing our portfolio helps add transparency to our business, builds trust with customers, and helps to share history of vintage guitars. Every instrument has a story, and we live to tell their stories.", link: "/portfolio", linkText: "Explore our portfolio." },
		{ img: "/img/consign.png", title: "Consignments", description: "For many years, we've been dedicated to helping guitar enthusiasts, from individual collectors to musicians and even guitar shops, with the buying, selling, and trading of unique and hard-to-find guitars. To learn more about how we can assist you sell a guitar, take a look at our", link: "/saleterms", linkText: "Sales and Consignment Terms." },
		{ img: "/img/appraise.png", title: "Appraisals", description: "Our Appraisal service is offered only with a in-hand inspection, and includes a highly detailed document (with professional pictures) that is signed and dated. We proudly stand behind our appraisals and don't take lightly putting our name behind them. To learn more about our appraisal process,", link: "/appraisals", linkText: "explore our Appraisal section." },
		{ img: "/img/restore.png", title: "Restorations", description: "Our ToneTeam offers our customers the best possible assistance for any restoration need, from the simple maintenance to the most difficult repair, and we're proud to collaborate with some of the finest luthiers around. We will supervise each and every step of the Restoration Process, keeping customers informed.", link: "/restorations", linkText: "Explore some of our restoration projects." },
		{ img: "/img/toneteam.png", title: "ToneTeam", description: "Our ToneTeam builds faithful recreations of vintage instruments with the highest quality materials and techniques available. They will be totally handcrafted in Italy, in a very limited number of pieces per year, with same techniques and specs as the originals.", link: "/toneteam", linkText: "Explore some of our ToneTeam builds." },
		{ img: "/img/blog.png", title: "Blog", description: "We'll often share interesting findings from guitars that cross our bench, investigation into instrument history, and exploration of original building techniques from the 50s and 60s. Check back often for our latest post and feel free to contact us with future post topics.", link: "/toneteam", linkText: "Explore our blog." },
	];
    
    // return the JSX
    return (
		<Grid container>
			<section className="home-hero parallax--bg">
				<div className="container">
					<h1 className="title parallax--box">CESCO'S<br/>CORNER<br/>GUITARS</h1>
				</div>
			</section>
			<div className="container2" style={{ marginTop: "20px" }}>
			{items.map((item, index) => (
				<Grid container key={item.title} spacing={2} direction={isSmallScreen ? "column" : "row"} alignItems="flex-start" justifyContent="center" sx={{ mb: 4 }}>
					<Grid item xs={12} sm={5} md={4} order={isSmallScreen ? 0 : index % 2 === 0 ? 1 : 2} sx={{ display: "flex", justifyContent: "flex-start", position: "relative" }}>
						<Box component="img" src={item.img} alt={item.title} sx={{ maxHeight: isSmallScreen ? "1000px" : "250px", width: "100%", height: "auto", objectFit: "cover" }}/>
						{isSmallScreen && (
						<Box sx={{ position: "absolute", bottom: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "8px", paddingLeft: "25px", width: "100%" }}>
							<Typography variant="h4">{item.title}</Typography>
						</Box>
						)}
					</Grid>
					<Grid item xs={12} sm={7} md={8} order={isSmallScreen ? 1 : index % 2 === 0 ? 2 : 1} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left" }}>
						{!isSmallScreen && <Typography variant="h4">{item.title}</Typography>}
						<Typography variant={isLargeScreen ? "h5" : (isMedScreen ? "body1" : "h6")}>{item.description}&nbsp;<a href={item.link}>{item.linkText}</a></Typography>
					</Grid>
				</Grid>
			))}
			</div>
			<section className="cta" style={{width: "50%", margin: "0 auto"}}>
				<div className="container" style={{textAlign: "center", paddingBottom: "40px"}}>
					<h1 className="title titleFooter">Do you have a vintage guitar left in the dust?
						<span>What are you waiting for?</span>
					</h1>
					<a href="/contact" className="button button-dark">CONTACT US!</a>
				</div>
			</section>
		</Grid>
    );
}

export default Home;
