import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";

function About() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const items = [
		{ img: "/img/francesco.png", title: "Francesco Balossino", description: "Francesco created Cescoscornerguitars in 2012, with the aim to build a safe place for musician and collectors where to share the amazing history and music that “vintage guitars” represent. He&apos;s been one of the very first in the Vintage Business, to share a public “portfolio” of all the guitars that has been appraised by him, with pictures and serial number shown, that has proved to be a powerful resource to track down info and history of each one of them. His passion and dedication have brought him to be one of the most respected vintage guitar expert in the business." },
		{ img: "/img/mattia.png", title: "Mattia Tedesco", description: "Mattia is one of the most important session musicians in Italy, and his studio work is second to none. He joined Cescoscornerguitars a few years ago, but he&apos;s been a good friend with Francesco  since forever. They both share the same, genuine, passion for music and guitars, and Mattia personally inspect and plays every guitar that comes in, to be absolutely ready for the Stage!" },
		{ img: "/img/portfolio.png", title: "Hours & Contact", description: "<p>Hours:<br/>6pm - 10pm Monday to Friday by appointment only.<br/>10am - 6pm on Saturday by appointment only.<p><p>Contact us via e-mail by clicking <a href='mailto:cescoscornerguitars@gmail.com'>here</a></p>" },
	];

  return (
    <div>
      <section className={"section-hero parallax--bg about"}>
          <div className="container">
              <h1 className="parallax--box">ABOUT</h1>
              <p></p>
          </div>
      </section>
      <div className="container2">
        <h2>Our Team</h2>
        {items.map((item, index) => (
        <Grid container key={item.title} spacing={2} direction={isSmallScreen ? "column" : "row"} alignItems="flex-start" justifyContent="center" sx={{ mb: 4 }}>
					<Grid item xs={12} sm={5} md={6} order={isSmallScreen ? 0 : index % 2 === 0 ? 1 : 2} sx={{ display: "flex", justifyContent: "flex-start", position: "relative" }}>
						<Box component="img" src={item.img} alt={item.title} sx={{ maxHeight: isSmallScreen ? "1000px" : "250px", width: "100%", height: "auto", objectFit: "cover" }}/>
						{isSmallScreen && (
						<Box sx={{ position: "absolute", bottom: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "8px", paddingLeft: "25px", width: "100%" }}>
							<Typography variant="h4">{item.title}</Typography>
						</Box>
						)}
					</Grid>
					<Grid item xs={12} sm={7} md={6} order={isSmallScreen ? 1 : index % 2 === 0 ? 2 : 1} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left" }}>
						{!isSmallScreen && <Typography variant="h4">{item.title}</Typography>}
						<Typography variant={isLargeScreen ? "h6" : (isMedScreen ? "body1" : "body1")}  dangerouslySetInnerHTML={{__html: item.description}}></Typography>
					</Grid>
				</Grid>
        ))}
      </div>
    </div>
  );
}

export default About;