import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as G from "../../models/gear";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface GearProps {
    collection: string;
    onWait: (wait: boolean) => void;
}

interface Filters {
    type:string;
    year:string;
    make:string;
    model:string;
}

const Gear: React.FC<GearProps> = ({onWait, collection}) =>{
    const [filters, setFilters] = useState<Filters>({
        type: "",
        year: "",
        make: "",
        model: ""
    });
    const [sort, setSort] = useState<string>((collection === "inventory") ? "recent" : "yearasc");
    const [gear, setGear] = useState<G.default[]>([]);
    const literals = {
        inventory: { title: "GEAR FOR SALE", subtitle: "Amazing gear available for purchase.", title2: "AVAILABLE ITEMS FOR SALE", subtitle2: "All the guitars listed here come from private collectors, and they will be fully checked, setup'd and appraised here at CescosCorner. You can contact us for any info regarding guitar details, sound, tone and feel, shipping/delivery options and purchase proposals. The inventory is updated daily."},
        portfolio: { title: "PORTFOLIO", subtitle: "Gallery of amazing past inventory.", title2: "ITEMS WE'VE SOLD", subtitle2: "Explore some of our former inventory that is now in the hands of happy players and collectors. Having a public portfolio is demonstrates our commitment to inventory accuracy and standing behind what we sell. It also serves as a great mechanism for sharing history."},
        personal: { title: "PERSONAL COLLECTION", subtitle: "Personal gear we're proud to share.", title2: "PERSONAL/NFS", subtitle2: "Here are some proud selections from our personal collection. Every guitar has a unique story and we are humbled to be the current caretakers of some incredible history."}
    }
    
    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        const firstLoad = async function () {
            const response = await fetch(`/api/GetGear?collection=${collection}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();
            setGear(json);
            onWait(false);
        };
        firstLoad();
    }, []);

    const uniqueYears: string[] = Array.from(
        new Set(gear.map(item => item.year))
    );
    const uniqueMakes: string[] = Array.from(
        new Set(gear.map(item => item.make))
    );
    const uniqueModels: string[] = Array.from(
        new Set(gear.map(item => item.model))
    );
    const yearOptions = uniqueYears.sort((a, b) => a.localeCompare(b)).map((year:string, index: number) => (
        <MenuItem value={year}>{year}</MenuItem>
    ));
    const makeOptions = uniqueMakes.sort((a, b) => a.localeCompare(b)).map((make:string, index: number) => (
        <MenuItem value={make}>{make}</MenuItem>
    ));
    const modelOptions = uniqueModels.sort((a, b) => a.localeCompare(b)).map((model:string, index: number) => (
        <MenuItem value={model}>{model}</MenuItem>
    ));
    let sortItems = [
        <MenuItem value="yearasc">Year (old to new)</MenuItem>,
        <MenuItem value="yeardesc">Year (new to old)</MenuItem>
    ];
    if (collection === "inventory") {
        sortItems.unshift(<MenuItem value="recent">Recent</MenuItem>);
        sortItems.push(<MenuItem value="priceasc">Price (high to low)</MenuItem>);
        sortItems.push(<MenuItem value="pricedesc">Price (low to high)</MenuItem>);
    }

    const items = gear.sort((a, b) => {
        if (sort === "yearasc")
            return a.year.localeCompare(b.year);
        else if (sort === "yeardesc")
            return b.year.localeCompare(a.year);
        else if (sort === "priceasc") {
            const aPrice = parseFloat(a.price || "") || (isNaN(parseFloat(a.price || "")) ? Number.POSITIVE_INFINITY : parseFloat(a.price || ""));
            const bPrice = parseFloat(b.price || "") || (isNaN(parseFloat(b.price || "")) ? Number.POSITIVE_INFINITY : parseFloat(b.price || ""));
            return bPrice - aPrice;
        }
        else if (sort === "pricedesc") {
            const aPrice = parseFloat(a.price || "") || (isNaN(parseFloat(a.price || "")) ? Number.POSITIVE_INFINITY : parseFloat(a.price || ""));
            const bPrice = parseFloat(b.price || "") || (isNaN(parseFloat(b.price || "")) ? Number.POSITIVE_INFINITY : parseFloat(b.price || ""));
            return aPrice - bPrice;
        }
        else {
            const aDate = a.created ? new Date(a.created) : null;
            const bDate = b.created ? new Date(b.created) : null;
            if (!aDate && !bDate) return 0;
            if (!aDate) return 1;
            if (!bDate) return -1;
            
            return bDate.getTime() - aDate.getTime();
        }
    }).map((item:G.default, index:number) => {
        if ((filters.type === "" || (filters.type !== "" && filters.type === item.type)) &&
            (filters.year === "" || (filters.year !== "" && filters.year === item.year)) &&
            (filters.make === "" || (filters.make !== "" && filters.make === item.make)) &&
            (filters.model === "" || (filters.model !== "" && filters.model === item.model))) {
            return (
                <figure className="port-item" key={item.path}>
                    <a href={`/${collection}/${item.path}`}><img src={item.photos[0]} alt="portfolio item"/></a>
                    <figcaption className="port-desc">
                        <p>{(collection === "inventory") ? item.price : item.year}</p>
                        <a href={`/${collection}/${item.path}`} className="port-button">{item.title}</a>
                    </figcaption>
                </figure>);
        }
    });
    // return the JSX
    return (
        <div>
            <section className={`section-hero parallax--bg ${collection}`}>
                <div className="container">
                    <h1 className="parallax--box">{(literals as any)[collection].title}</h1>
                    <p>{(literals as any)[collection].subtitle}</p>
                </div>
            </section>
            <div className="container2">
	            <h2>{(literals as any)[collection].title2}</h2>
	            <p>{(literals as any)[collection].subtitle2}</p>
            </div>
            <div className="filter-bar">
                <div className="filters-inline">
                    <Box sx={{ minWidth: 160 }}>
                        <FormControl fullWidth>
                            <InputLabel id="typeLabel">Type</InputLabel>
                            <Select labelId="typeLabel" id="typeSelect1" name="type" label="Type" value={filters.type} onChange={(e) => {setFilters(f => ({...f, type: e.target.value as string}))}}>
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="Electric Guitar">Electric Guitar</MenuItem>
                                <MenuItem value="Acoustic Guitar">Acoustic Guitar</MenuItem>
                                <MenuItem value="Bass Guitar">Bass Guitar</MenuItem>
                                <MenuItem value="Amplifier">Amplifier</MenuItem>
                                <MenuItem value="Pedal/Effect">Pedal/Effect</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 160 }}>
                        <FormControl fullWidth>
                            <InputLabel id="yearLabel">Year</InputLabel>
                            <Select labelId="yearLabel" id="yearSelect" name="year" label="Year" value={filters.year} onChange={(e) => {setFilters(f => ({...f, year: e.target.value as string}))}}>
                                <MenuItem value=""></MenuItem>
                                {yearOptions}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 160 }}>
                        <FormControl fullWidth>
                            <InputLabel id="makeLabel">Make</InputLabel>
                            <Select labelId="makeLabel" id="makeSelect" name="make" label="Make" value={filters.make} onChange={(e) => {setFilters(f => ({...f, make: e.target.value as string}))}}>
                                <MenuItem value=""></MenuItem>
                                {makeOptions}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 160 }}>
                        <FormControl fullWidth>
                            <InputLabel id="modelLabel">Model</InputLabel>
                            <Select labelId="modelLabel" id="modelSelect" name="model" label="Model" value={filters.model} onChange={(e) => {setFilters(f => ({...f, model: e.target.value as string}))}}>
                                <MenuItem value=""></MenuItem>
                                {modelOptions}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 160 }}>
                        <FormControl fullWidth>
                            <InputLabel id="sortLabel">Sort</InputLabel>
                            <Select labelId="sortLabel" id="sortSelect" name="sort" label="Sort" value={sort} onChange={(e) => setSort(e.target.value)}>
                                {sortItems}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 160 }} style={(filters.type === "" && filters.year === "" && filters.make === "" && filters.model === "")? {display: "none"} : {display: "flex", paddingTop: "10px"}}>
                        <FormControl fullWidth>
                            <Button onClick={() => setFilters({type: "",year: "",make: "",model: ""})}>Clear Filters</Button>
                        </FormControl>
                    </Box>
                </div>
                <div className="filters-collapsed">
                    
                </div>
            </div>
            <section className="portfolio">
                {items}
            </section>
            <div></div>
        </div>
    );
}

export default Gear;
