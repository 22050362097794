import {
    createBrowserRouter,
    Link,
    Route,
    RouterProvider,
    Routes,
  } from "react-router-dom";
  import App from "./App";
  
  // 3️Router singleton created
  const router = createBrowserRouter([
    { path: "*", Component: App },
    ]);
  
  // 4️⃣ RouterProvider added
  export default function RouteWrapper() {
    return <RouterProvider router={router} />;
  }