
import "./SiteHeader.css";
import { Box, Grid } from "@mui/material";

function Footer() {
    return (
        <footer>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={{textAlign: "center"}}>
			        <div className="logoFooter"><img src="/img/logo.png" alt=""/></div>
			        <p>Cesco's Corner Guitars is the place for any vintage guitar enthusiast! Here we share with you our knowledge, history and passion to provide the most valuable "vintage experience" possible.</p>
		        </Grid>
                <Grid container spacing={2} xs={12} md={6}>
                    <Grid item xs={4}>  
                        <ul className="unstyled-list">
                            <li><strong>ABOUT</strong></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/personal">Private Collection</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={4}> 
                        <ul className="unstyled-list">
                            <li><strong>VINTAGE HELP</strong></li>
                            <li><a href="/inventory">Gear for Sale</a></li>
                            <li><a href="/portfolio">Portfolio</a></li>
                            <li><a href="/appraisals">Appraisals</a></li>
                            <li><a href="/saleterms">Consignments &amp; Sales</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={4}> 
                        <ul className="unstyled-list">
                            <li><strong>OTHER</strong></li>
                            <li><a href="/toneteam">Tone Team</a></li>
                            <li><a href="/restorations">Restore Department</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <p>© 2024 CescosCornerGuitars.com</p>
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;