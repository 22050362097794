import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

interface RichTextEditorProps {
  value:string;
  label:string;
  onChange: (content: string) => void;
  onImageSelected: (file: any) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({label, value, onChange, onImageSelected}) =>{
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ "header": [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ "list": "ordered" }, { "list": "bullet" }],
              ["image", "video"], // Custom image button
            ],
            handlers: {
              image: () => fileInputRef.current?.click(), // Trigger file input on image click
              video: handleEmbedVideo
            },
          },
        },
      });

      // Set the editor's initial value
      quillRef.current.root.innerHTML = value;

      // Get toolbar reference after Quill initializes it
      const toolbarElement = editorRef.current?.parentElement?.querySelector(
        ".ql-toolbar"
      ) as HTMLDivElement;
      setToolbarRef(toolbarElement);

      // Handle editor changes and propagate to parent via onChange
      quillRef.current.on("text-change", () => {
        const editorHtml = quillRef.current?.root.innerHTML || "";
        onChange(editorHtml);
      });

      // Listen for focus and blur events to update state
      quillRef.current.on("selection-change", (range) => {
        if (range) {
          setIsFocused(true); // Editor is focused
        } else {
          setIsFocused(false); // Editor is blurred
        }
      });
    }
  }, []);

  // Update Quill content when the `value` prop changes
  useEffect(() => {
    if (quillRef.current && value !== quillRef.current.root.innerHTML) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  // Handle file selection
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const f = await readFile(e.target.files, 0);
      const range = quillRef.current?.getSelection();
      const newUri = await onImageSelected(f);
      if (range) { 
        quillRef.current?.insertEmbed(range.index, "image", newUri);
      }
    }
  };

  // Handle embedding a video
  const handleEmbedVideo = () => {
    const videoUrl = prompt("Enter the YouTube video URL:");
    if (videoUrl) {
      const videoId = extractYouTubeVideoId(videoUrl);
      if (videoId) {
        const range = quillRef.current?.getSelection();
        const embedHtml = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
        if (range) {
          quillRef.current?.clipboard.dangerouslyPasteHTML(range.index, embedHtml);
        } else {
          // Fallback if no range is selected, insert at the end
          quillRef.current?.clipboard.dangerouslyPasteHTML(quillRef.current?.getLength() || 0, embedHtml);
        }
      } else {
        alert("Invalid YouTube URL. Please enter a valid URL.");
      }
    }
  };

  // Utility function to extract the YouTube video ID from a URL
  const extractYouTubeVideoId = (url: string) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? match[1] : null;
  };

  // reads the file as a base64 encoded string
  const readFile = (files:any, index:number) => {
    return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        reader.onloadend = function() {
            resolve({ photo: reader.result, index: index, name: files[index].name, file: files[index]});
        }
        reader.readAsDataURL(files[index]);
    });
  };

  // Apply style to the toolbar via direct manipulation
  useEffect(() => {
    if (toolbarRef) {
      toolbarRef.style.borderColor = isFocused ? "#90caf9" : "rgba(255,255,255,0.23)"; // Change toolbar background color
    }
  }, [isFocused, toolbarRef]);
  
  return (
    <div>
      <label className={"rtl MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined css-axj3wf-MuiFormLabel-root-MuiInputLabel-root " + (isFocused ? "focus" : "")} data-shrink="true">{label}</label>

      {/* Editor */}
      <div ref={editorRef} className={(isFocused) ? "ql-container ql-snow focus" : "ql-container ql-snow"} style={{ height: "300px" }} />
      {/* Hidden file input */}
      <input
        type="file"
        accept="image/x-png,image/jpeg,image/webp"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default RichTextEditor;
