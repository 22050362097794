import { useState, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import * as S from "../../models/spotlight";
import { Box, Chip, Typography } from "@mui/material";

interface BlogProps {
    onWait: (wait: boolean) => void;
}

const Blog: React.FC<BlogProps> = ({onWait}) =>{
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [blogs, setBlogs] = useState<S.default[]>([]);
    const [tag, setTag] = useState<string | null>();
    
    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        setTag(searchParams.get("tag"));
        const firstLoad = async function () {
            const response = await fetch("/api/GetSpotlight?type=blog", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();
            setBlogs(json);
            onWait(false);
        };
        firstLoad();
    },[searchParams]);

    const items = blogs.filter(i => {
        if (tag === null) {
            return true;
        }
        return i.tags.includes(tag as string);
    }).map((item:S.default, index:number) => (
        <Box key={index} className="container" sx={{ display: "flex", margin: 5 }}>
            {/* Image to the left */}
            <Box component="img" src={item.photos[0]} alt={item.title} sx={{ width: 100, height: 100, objectFit: "cover", borderRadius: 1, marginRight: 2 }}/>
            
            {/* Content to the right of the image */}
            <Box>
                <Typography variant="h5" component="h2" sx={{ marginBottom: 0, paddingLeft: "20px" }}>
                    <a href={`/blog/${item.path}`}>{item.title}</a>
                </Typography>

                {/* Publish Date */}
                <Typography variant="body2" sx={{ marginTop: 0, paddingLeft: "20px" }}>
                    Published {new Date(item.created as Date).toLocaleDateString()}
                </Typography>
                {/* Tags */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, paddingLeft: "20px" }}>
                    <Typography variant="subtitle1" component="span">Tags:</Typography>
                    {item.tags.map((tag: string, index: number) => (
                        <Link to={`/blog?tag=${tag}`} key={index} style={{ textDecoration: "none" }}>
                            <Chip label={tag} clickable color="primary" component="div" />
                        </Link>
                    ))}
                </Box>
                {/* Abstract */}
                <Typography variant="body2" sx={{ marginTop: 0, paddingLeft: "20px" }}>
                    {item.subTitle}
                </Typography>
            </Box>
        </Box>
    ));
    // return the JSX
    return (
        <div>
            <section className={"section-hero parallax--bg blog"}>
                <div className="container">
                    <h1 className="parallax--box">CESCO'S BLOG</h1>
                    <p>Sharing learnings and history from the bench.</p>
                </div>
            </section>
            <div>
                {items}
            </div>
        </div>
    );
}

export default Blog;
