import React, { useState } from "react";
import { Box, Chip, TextField } from "@mui/material";

interface TagInputProps {
    value:string[];
    onTagAdded: (content: string) => void;
    onTagDeleted: (content: string) => void;
  }

  const TagInput: React.FC<TagInputProps> = ({value, onTagAdded, onTagDeleted}) =>{
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      onTagAdded(inputValue.trim());
      setInputValue("");
    }
  };

  const handleDelete = (tagToDelete: string) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    onTagDeleted(tagToDelete);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', border: (hasFocus) ? '2px solid #90caf9' : '1px solid rgba(255, 255, 255, 0.23)', borderRadius: 1, padding: '6px 8px', gap: 1 }}>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => handleDelete(tag)}
          color="primary"
          size="small"
          sx={{ margin: "2px 0" }}
        />
      ))}

      <TextField
        className="tagInput"
        variant="outlined"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Press Enter to add tag"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        InputProps={{
          sx: { 
            display: 'inline-flex',
            flexGrow: 1,
            minWidth: '150px', // Set a minimum width for the input
            '& .MuiInputBase-input': {
              padding: '6px 8px', // Adjust padding for better appearance
            }
          }
        }}
        sx={{
          flexGrow: 1,
          width: 'auto',
        }}
      />
    </Box>
  );
};

export default TagInput;