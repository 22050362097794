import { useContext, useEffect, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";

interface ImageUploaderItemProps {
    image:string;
    onDelete: (index: number) => void;
    onReorder: (index: number, direction: number) => void;
    index: number;
    size: number;
    disabled: boolean;
}

const ImageUploaderItem: React.FC<ImageUploaderItemProps> = ({image, onDelete, onReorder, index, size, disabled}) =>{
    const overlay = (disabled) ? (<><div className="img-uploader-img-overlay" ></div><div className="spinner-border text-primary" role="status"></div></>) : (<></>);
    const style = { backgroundImage: "url(" + image +")"};
    return (
        <div className="img-uploader-item">
            {overlay}
            <div className="img-uploader-img" style={style}></div>
            <div style={{textAlign: "center", fontSize: "10px"}}>
                <button className="btn btn-light" onClick={() => onReorder(index, 1)} disabled={index == 0}><KeyboardDoubleArrowLeftIcon/></button>&nbsp;
                <button className="btn btn-light" onClick={() => onReorder(index, 2)} disabled={index == 0}><KeyboardArrowLeftIcon/></button>&nbsp;
                <button className="btn btn-light" onClick={() => onDelete(index)}><DeleteIcon/></button>&nbsp;
                <button className="btn btn-light" onClick={() => onReorder(index, 3)} disabled={index == size - 1}><KeyboardArrowRightIcon/></button>&nbsp;
                <button className="btn btn-light" onClick={() => onReorder(index, 4)} disabled={index == size - 1}><KeyboardDoubleArrowRightIcon/></button>
            </div>
        </div>
    );
}

export default ImageUploaderItem;