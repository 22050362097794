import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";

function Waiting() {
    
  return (
    <div className="waiting_wrapper">
        <div className="waiting_progress">
        <CircularProgress></CircularProgress>
        </div>
        <div className="waiting_opac"></div>
    </div>
  );
}

export default Waiting;
