import { useEffect, useState, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ImageUploader from "../general/ImageUploader";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Switch } from "@mui/material";
import Spotlight from "../../models/spotlight";
import { AccountInfo } from "@azure/msal-browser";
import RichTextEditor from "../general/RichTextEditor";
import TagInput from "../general/TagInput";

interface AddEditBlogProps {
    onWait: (wait: boolean) => void;
}

const AddEditBlog: React.FC<AddEditBlogProps> = ({onWait}) =>{
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [account, setAccount] = useState<AccountInfo>();
    const [spotlight, setSpotlight] = useState<Spotlight>({
            type: "blog",
            path: "",
            title: "",
            subTitle: "",
            description: "",
            photos: [],
            active: false,
            tags: []
        });


    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        window.scrollTo(0, 0);
        const firstLoad = async function () {
            await instance.initialize(); // Initialize MSAL
            await instance.handleRedirectPromise(); 
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                instance.loginRedirect({
                    scopes: ["User.Read"]
                });
            }
            else {
                setAccount(accounts[0]);
                // authorize
                const authResp = await fetch("/api/Authorize", { method: "POST", headers: {
                    "Authorization": `Bearer ${accounts[0].idToken}`,
                    "Content-Type": "application/json",
                }});
                if (!authResp.ok) {
                    navigate("/");
                }

                if (params.path) {
                    // this is an edit...get the item from database
                    const response = await fetch(`/api/GetSpotlightDetail?path=${params.path}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const s = await response.json();
                    setSpotlight(s);
                    onWait(false);
                }
                else {
                    // this is a create...initialize an empty gear object
                    onWait(false);
                }
            }
        };
        firstLoad();
    },[location.pathname]);

    const filesPicked = async (fileInfo:any) => {
        // add the new file to state using local URI (fileInfo.photo)
        setSpotlight(prevSpot => ({ ...prevSpot, photos: [...prevSpot.photos, fileInfo.photo] }));

        let formData = new FormData();
        formData.append(fileInfo.name, fileInfo.file);
        //console.log("File buffer size: ", fileInfo.file.size);
        const response = await fetch(`/api/AddPhoto?path=${spotlight.path}&type=Spotlight`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${account?.idToken}`
            },
            body: formData
        });
        
        if (response.ok) {
            const newUri = await response.text();
            setSpotlight(prevSpot => ({
                ...prevSpot,
                photos: prevSpot.photos.map(photo => 
                    photo === fileInfo.photo ? newUri : photo
                )
            }));
            return newUri;
        }
        else
            return null;
    }

    // handles input changes
    const handleInputChange = async (event:any) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        setSpotlight((prevSpot: any) => {
            if (!prevSpot) return { [fieldName]: fieldVal }; 
            return { 
                ...prevSpot, 
                [fieldName]: fieldVal
            };
        });
    };

    const save = async () => {
        console.log(spotlight);
        onWait(true);
        if (spotlight.id) {
            // update
            const response = await fetch("/api/UpdateSpotlight", {
                method: "PATCH",
                headers: {
                    "Authorization": `Bearer ${account?.idToken}`,
                    "Content-Type": "application/json",
                    "Accept":  "application/json"
                },
                body: JSON.stringify(spotlight)
            });
            //TODO: check if success
            navigate("/admin");
        }
        else {
            // create
            const response = await fetch("/api/CreateSpotlight", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${account?.idToken}`,
                    "Content-Type": "application/json",
                    "Accept":  "application/json"
                },
                body: JSON.stringify(spotlight)
            });
            var newSpot:Spotlight = await response.json();
            console.log(newSpot);
            navigate(`/admin/blog/edit/${newSpot.path}`);
        }
        onWait(false);
    };

    const blogChanged = (e:any) => {
        setSpotlight(prevSpot => ({
            ...prevSpot, description: e
        }));
    };

    // return the JSX
    return (
        <div>
            <section className={"section-hero parallax--bg"}>
                <div className="container">
                    <h1 className="parallax--box">SPOTLIGHT - {(spotlight.id) ? "EDIT" : "CREATE"}</h1>
                    <p>Use this form to configure the new spotlight item</p>
                </div>
            </section>
            <div style={{padding: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" gap={2} style={{ paddingBottom: "10px" }}>
                            <Button onClick={() => { navigate("/admin") }} variant="outlined">Cancel</Button>
                            <Button onClick={save} variant="outlined">Save</Button>
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth style={{paddingBottom: "10px", display: (spotlight.id) ? "block" : "none"}}>
                            <InputLabel shrink={true} id="activeLabel">Active</InputLabel>
                            <Switch checked={spotlight.active} value={spotlight?.active} onChange={(event:any) => { setSpotlight(prevSpot => ({...prevSpot, active: !prevSpot.active}))}}></Switch>
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <InputLabel htmlFor="titleInput">Title</InputLabel>
                            <OutlinedInput id="titleInput" name="title" label="Title" value={spotlight?.title} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <InputLabel htmlFor="subTitleInput">Abstract</InputLabel>
                            <OutlinedInput multiline id="subTitleInput" name="subTitle" label="Abstract" rows={6} value={spotlight?.subTitle} onChange={handleInputChange}/>
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <TagInput value={spotlight.tags} 
                                onTagAdded={(tag:string) => setSpotlight((prevSpot:Spotlight) => ({...prevSpot, tags: [...prevSpot.tags, tag]}))} 
                                onTagDeleted={(tag:string) => setSpotlight(prevSpot => ({...prevSpot, tags: prevSpot.tags.filter(str => str !== tag)}))}></TagInput>
                        </FormControl>
                        {(spotlight.id) ? (
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <RichTextEditor value={spotlight.description} label="Blog Post" onChange={blogChanged} onImageSelected={filesPicked} />
                        </FormControl>
                        ) : (<></>)}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default AddEditBlog;
