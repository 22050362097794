import { Configuration } from "@azure/msal-browser";

// Determine if the app is running locally or in production
const isLocalhost = window.location.hostname === "localhost";

// Set the redirectUri dynamically based on the environment
const redirectUri = isLocalhost
  ? "http://localhost:3000/admin"
  : "https://cescoscornerguitars.azurewebsites.net/admin";

const msalConfig: Configuration = {
  auth: {
    clientId: "11889238-fb5e-418e-ba0e-d4a09e8bd537", // Entra ID (Azure AD) App Registration
    authority: "https://login.microsoftonline.com/consumers", // for MSA accounts
    redirectUri: redirectUri, // Change this to match your redirect URI
  },
  cache: {
    cacheLocation: "sessionStorage", // 'sessionStorage' or 'localStorage'
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read", "api://11889238-fb5e-418e-ba0e-d4a09e8bd537/access_as_user"], // Add necessary scopes
};

export default msalConfig;