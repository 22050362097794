import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SiteHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem } from "@mui/material";

function SiteHeader() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = (path:string) => {
        setAnchorEl(null);
        navigate(path);
    };

    const menuItems = [
        { name: "Home", path: "/" },
        { name: "Inventory", path: "/inventory" },
        { name: "About", path: "/about" },
        { name: "Blog", path: "/blog" },
        { name: "Contact", path: "/contact" }
    ];

    let items = menuItems.map((item:any, index: number) => (
        <li key={item.path}><a href={item.path}>{item.name}</a></li>
    ));
    let itemsSmall = menuItems.map((item:any, index: number) => (
        <MenuItem key={item.path} onClick={() => {handleMenuClose(item.path)}}>
            {item.name}
        </MenuItem>
    ));
    return (
    <header>
        <img src="/img/logo.png" alt="Cesco's Corner logo" className="logo"/>
        <nav>
            <ul>
                {items}
                <div id="socialBlock">
                    <li className="social">
                        <a target="_new" href="https://www.facebook.com/Cescoscornerguitars/?modal=admin_todo_tour"><img src= "/img/fb_icon.svg" alt="Cesco's Corner Facebook Page" className="snIcon"/></a>
                    </li>
                    <li className="social">
                        <a target="_new" href="https://www.instagram.com/cesco_s_corner/"><img src= "/img/instagram_icon.svg" alt="Cesco's Corner Instagram Page" className="snIcon"/></a>
                    </li>
                    <li className="social">
                        <a target="_new" href="https://www.youtube.com/channel/UCtxAfjW4dUdsFhLRbOvNpsA/"><img src= "/img/yt_icon.svg" alt="Cesco's Corner Youtube Page" className="snIcon"/></a>
                    </li>
                </div>
            </ul>
        </nav>
        <div className="nav-burger">
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ ml: 'auto' }}>
                <MenuIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {itemsSmall}
            </Menu>
        </div>
    </header>
  );
}

export default SiteHeader;