import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Gear from "../../models/gear";
import "./GearDetail.css";

interface GearDetailProps {
    onWait: (wait: boolean) => void;
}

const GearDetail: React.FC<GearDetailProps> = ({onWait}) =>{
    const params = useParams();
    const [gear, setGear] = useState<Gear>({
        type: "",
        year: "",
        make: "",
        model: "",
        serialNumber: "",
        path: "",
        title: "",
        description: "",
        collection: "",
        photos: [],
        price: "",
        active: false
    });
    const literals = {
        inventory: { title: "GEAR FOR SALE", subtitle: "Amazing gear available for purchase.", title2: "AVAILABLE ITEMS FOR SALE", subtitle2: "All the guitars listed here come from private collectors, and they will be fully checked, setup'd and appraised here at CescosCorner. You can contact us for any info regarding guitar details, sound, tone and feel, shipping/delivery options and purchase proposals. The inventory is updated daily."},
        portfolio: { title: "PORTFOLIO", subtitle: "Gallery of amazing past inventory.", title2: "ITEMS WE'VE SOLD", subtitle2: "Explore some of our former inventory that is now in the hands of happy players and collectors. Having a public portfolio is demonstrates our commitment to inventory accuracy and standing behind what we sell. It also serves as a great mechanism for sharing history."},
        personal: { title: "PERSONAL COLLECTION", subtitle: "Personal gear we're proud to share.", title2: "PERSONAL/NFS", subtitle2: "Here are some proud selections from our personal collection. Every guitar has a unique story and we are humbled to be the current caretakers of some incredible history."}
    };
    
    
    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        const firstLoad = async function () {
            const response = await fetch(`/api/GetGearDetail?path=${params.path}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();
            setGear(json);
            onWait(false);
        };
        firstLoad();
    }, []);
    
    let photos = gear.photos.map((item:string, index:number) => (
        <figure className="gal-item">
            <img src={item} alt="gallery item"/>
        </figure>
    ));
    // return the JSX
    return (
        <>
            <section className={`section-hero parallax--bg ${gear.collection}`}>
                <div className="container">
                    <h1 className="parallax--box" style={{textTransform: "uppercase"}}>{gear.collection}</h1>
                    <p>{(gear.collection !== "") ? (literals as any)[gear.collection].subtitle : ""}</p>
                </div>
            </section>
            <div className="section-text">
	            <h2>{gear.title}</h2>
                { (gear.collection === "inventory") ? (<h2>Price: {gear.price}</h2>) : (<></>)}
	            <p dangerouslySetInnerHTML={{__html: gear.description.replaceAll("\n", "<br/>")}}></p>
	        </div>
	        <section className="gallery">
                {photos}
            </section> 
        </>
    );
}

export default GearDetail;