import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spotlight from "../../models/spotlight";
import { Box, Chip, Typography } from "@mui/material";

interface BlogDetailProps {
    type:string;
    onWait: (wait: boolean) => void;
}

const BlogDetail: React.FC<BlogDetailProps> = ({onWait, type}) =>{
    const params = useParams();
    const [blog, setBlog] = useState<Spotlight>({
        type: "",
        path: "",
        title: "",
        description: "",
        photos: [],
        active: false,
        tags: []
    });
    
    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        const firstLoad = async function () {
            const response = await fetch(`/api/GetSpotlightDetail?path=${params.path}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();
            setBlog(json);
            onWait(false);
        };
        firstLoad();
    }, []);

    const tags = (
        <Box sx={{display: "flex",flexWrap: "wrap",gap: 1, paddingLeft: "20px"}}>
            <Typography variant="subtitle1" component="span">
            Tags:
            </Typography>
            {blog.tags.map((tag:string, index:number) => (
                <Link to={`/blog?tag=${tag}`}  key={index} style={{ textDecoration: "none" }}>
                    <Chip label={tag} clickable color="primary" component="div"/>
                </Link>
            ))}
        </Box>
    );
    
    // return the JSX
    return (
        <>
            <section className={"section-hero parallax--bg blog"}>
                <div className="container">
                    <h1 className="parallax--box">CESCO'S BLOG</h1>
                    <p>Sharing learnings and history from the bench.</p>
                </div>
            </section>
            <div className="section-text" style={{paddingTop: "20px"}}>
                <h2>{blog.title}</h2>
                {tags}
	            <p dangerouslySetInnerHTML={{__html: blog.description}}></p>
	        </div>
        </>
    );
}

export default BlogDetail;