import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SvgIconComponent } from "@mui/icons-material";


interface HoverIconProps {
    Icon: SvgIconComponent;
    onClick?: () => void;
}

const HoverIcon: React.FC<HoverIconProps> = ({ Icon, onClick }: HoverIconProps) =>{
  return (
    <Box
        onClick={onClick}
        sx={{
            display: "inline-block",
            "&:hover": {
                color: "#999",
                transform: "scale(1.2)",
                transition: "transform 0.1s, color 0.1s ease-in-out",
                cursor: "pointer"
            },
        }}>
      <Icon fontSize="medium" className="MuiSvgIcon-root" />
    </Box>
  );
}

export default HoverIcon;