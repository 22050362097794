import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./components/static/Home";
import About from "./components/static/About";
import Waiting from "./components/general/Waiting";
import Admin from "./components/admin/Admin";
import Gear from "./components/dynamic/Gear";
import GearDetail from "./components/dynamic/GearDetail";
import SiteHeader from "./components/general/SiteHeader";
import Footer from "./components/general/Footer";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import AddEditGear from "./components/admin/AddEditGear";
import Spotlight from "./components/dynamic/Spotlight";
import SpotlightDetail from "./components/dynamic/SpotlightDetail";
import AddEditSpot from "./components/admin/AddEditSpot";
import AddEditBlog from "./components/admin/AddEditBlog";
import Blog from "./components/dynamic/Blog";
import BlogDetail from "./components/dynamic/BlogDetail";
import Contact from "./components/static/Contact";
import Appraisals from "./components/static/Appraisals";
import Privacy from "./components/static/Privacy";
import SaleTerms from "./components/static/SaleTerms";

// not used
import Services from "./components/static/Services";
import Friends from "./components/static/Friends";


function App() {
  const [wait, setWait] = useState<boolean>();
  const handleOnWait = (wait:boolean) => {
    setWait(wait);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#f48fb1",
      },
      background: {
        default: "#000000",
        paper: "#1d1d1d",
      },
    },
  });

  const waitingIndicator = (wait) ? (<Waiting></Waiting>) : (<></>);
  return (
    <div className="container">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        {waitingIndicator}
        <SiteHeader></SiteHeader>
        <Routes>
          <Route path="/" element={<Home onWait={handleOnWait} />} />

          {/* gear routes */}
          <Route path="/inventory" element={<Gear onWait={handleOnWait} collection="inventory" />} />
          <Route path="/inventory/:path" element={<GearDetail onWait={handleOnWait} />} />
          <Route path="/portfolio" element={<Gear onWait={handleOnWait} collection="portfolio" />} />
          <Route path="/portfolio/:path" element={<GearDetail onWait={handleOnWait} />} />
          <Route path="/personal" element={<Gear onWait={handleOnWait} collection="personal" />} />
          <Route path="/personal/:path" element={<GearDetail onWait={handleOnWait} />} />
          
          {/* admin routes */}
          <Route path="/admin" element={<Admin onWait={handleOnWait} />} />
          <Route path="/admin/gear/create" element={<AddEditGear onWait={handleOnWait} />} />
          <Route path="/admin/gear/edit/:path" element={<AddEditGear onWait={handleOnWait} />} />
          <Route path="/admin/spot/create" element={<AddEditSpot onWait={handleOnWait} />} />
          <Route path="/admin/spot/edit/:path" element={<AddEditSpot onWait={handleOnWait} />} />
          <Route path="/admin/spot/create" element={<AddEditBlog onWait={handleOnWait} />} />
          <Route path="/admin/spot/edit/:path" element={<AddEditBlog onWait={handleOnWait} />} />
          <Route path="/admin/blog/create" element={<AddEditBlog onWait={handleOnWait} />} />
          <Route path="/admin/blog/edit/:path" element={<AddEditBlog onWait={handleOnWait} />} />

          {/* spotlight routes */}
          <Route path="/events" element={<Spotlight onWait={handleOnWait} type="events" />} />
          <Route path="/events/:path" element={<SpotlightDetail onWait={handleOnWait} type="events" />} />
          <Route path="/restorations" element={<Spotlight onWait={handleOnWait} type="restorations" />} />
          <Route path="/restorations/:path" element={<SpotlightDetail onWait={handleOnWait} type="restorations" />} />
          <Route path="/toneteam" element={<Spotlight onWait={handleOnWait} type="toneteam" />} />
          <Route path="/toneteam/:path" element={<SpotlightDetail onWait={handleOnWait} type="toneteam" />} />
          <Route path="/blog" element={<Blog onWait={handleOnWait} />} />
          <Route path="/blog/:path" element={<BlogDetail onWait={handleOnWait} type="blog" />} />
          
          {/* static routes */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/appraisals" element={<Appraisals />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/saleterms" element={<SaleTerms />} />
          <Route path="*" element={<Navigate to={"/"} />}></Route>
        </Routes>
        <Footer></Footer>
      </ThemeProvider>
    </div>
  );
}

export default App;