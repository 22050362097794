import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ImageUploader from "../general/ImageUploader";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Switch } from "@mui/material";
import Spotlight from "../../models/spotlight";
import { AccountInfo } from "@azure/msal-browser";

interface AddEditGearProps {
    onWait: (wait: boolean) => void;
}

const AddEditGear: React.FC<AddEditGearProps> = ({onWait}) =>{
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [account, setAccount] = useState<AccountInfo>();
    const [spotlight, setSpotlight] = useState<Spotlight>({
            type: "",
            path: "",
            title: "",
            description: "",
            photos: [],
            active: false,
            tags: []
        });

    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        window.scrollTo(0, 0);
        const firstLoad = async function () {
            await instance.initialize(); // Initialize MSAL
            await instance.handleRedirectPromise(); 
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                instance.loginRedirect({
                    scopes: ["User.Read"]
                });
            }
            else {
                setAccount(accounts[0]);
                // authorize
                const authResp = await fetch("/api/Authorize", { method: "POST", headers: {
                    "Authorization": `Bearer ${accounts[0].idToken}`,
                    "Content-Type": "application/json",
                }});
                if (!authResp.ok) {
                    navigate("/");
                }
                
                if (params.path) {
                    // this is an edit...get the item from database
                    const response = await fetch(`/api/GetSpotlightDetail?path=${params.path}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const s = await response.json();
                    setSpotlight(s);
                    onWait(false);
                }
                else {
                    // this is a create...initialize an empty gear object
                    onWait(false);
                }
            }
        };
        firstLoad();
    },[location.pathname]);

    const filesPicked = async (fileInfo:any) => {
        // add the new file to state using local URI (fileInfo.photo)
        setSpotlight(prevSpot => ({ ...prevSpot, photos: [...prevSpot.photos, fileInfo.photo] }));

        let formData = new FormData();
        formData.append(fileInfo.name, fileInfo.file);
        console.log("File buffer size: ", fileInfo.file.size);
        const response = await fetch(`/api/AddPhoto?path=${spotlight.path}&type=Spotlight`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${account?.idToken}`
            },
            body: formData
        });
        
        if (response.ok) {
            const newUri = await response.text();
            console.log(newUri);
            setSpotlight(prevSpot => ({
                ...prevSpot,
                photos: prevSpot.photos.map(photo => 
                    photo === fileInfo.photo ? newUri : photo
                )
            }));
        }
    }

    // handles input changes
    const handleInputChange = async (event:any) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        setSpotlight((prevSpot: any) => {
            if (!prevSpot) return { [fieldName]: fieldVal }; 
            return { 
                ...prevSpot, 
                [fieldName]: fieldVal
            };
        });
    };

    const save = async () => {
        onWait(true);
        if (spotlight.id) {
            // update
            const response = await fetch("/api/UpdateSpotlight", {
                method: "PATCH",
                headers: {
                    "Authorization": `Bearer ${account?.idToken}`,
                    "Content-Type": "application/json",
                    "Accept":  "application/json"
                },
                body: JSON.stringify(spotlight)
            });
            //TODO: check if success
            navigate("/admin");
        }
        else {
            // create
            const response = await fetch("/api/CreateSpotlight", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${account?.idToken}`,
                    "Content-Type": "application/json",
                    "Accept":  "application/json"
                },
                body: JSON.stringify(spotlight)
            });
            var newSpot:Spotlight = await response.json();
            console.log(newSpot);
            navigate(`/admin/spot/edit/${newSpot.path}`);
        }
        onWait(false);
    };

    const reorderPic = (index:number, type:number) => {
        // get instance of item in array
        let photoUrl = spotlight.photos[index];

        // remove existing item from array
        setSpotlight(prevSpot => ({
            ...prevSpot,
            photos: prevSpot.photos.filter((_, idx) => idx !== index)
        }));

        // determine new index for item
        let newIndex:number = 0;
        if (type == 1)
            newIndex = 0;
        else if (type == 2)
            newIndex = index - 1;
        else if (type == 3)
            newIndex = index + 1;
        else if (type == 4)
            newIndex = spotlight.photos.length;

        // add the item back in the correct location
        console.log(`Move ${index} to ${newIndex}`);
        setSpotlight(prevSpot => ({
            ...prevSpot,
            photos: [
                ...prevSpot.photos.slice(0, newIndex), 
                photoUrl, 
                ...prevSpot.photos.slice(newIndex)
            ]
        }));
    };

    const deletePic = (index:number) => {
        // remove existing item from array
        setSpotlight(prevSpot => ({
            ...prevSpot,
            photos: prevSpot.photos.filter((_, idx) => idx !== index)
        }));
    };

    // return the JSX
    return (
        <div>
            <section className={"section-hero parallax--bg"}>
                <div className="container">
                    <h1 className="parallax--box">SPOTLIGHT - {(spotlight.id) ? "EDIT" : "CREATE"}</h1>
                    <p>Use this form to configure the new spotlight item</p>
                </div>
            </section>
            <div style={{padding: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" gap={2} style={{ paddingBottom: "10px" }}>
                            <Button onClick={() => { navigate("/admin") }} variant="outlined">Cancel</Button>
                            <Button onClick={save} variant="outlined">Save</Button>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={spotlight.id ? 6 : 12}>
                        <h3>Details</h3>
                        <FormControl fullWidth style={{paddingBottom: "10px", display: (spotlight.id) ? "block" : "none"}}>
                            <InputLabel shrink={true} id="activeLabel">Active</InputLabel>
                            <Switch checked={spotlight.active} value={spotlight?.active} onChange={(event:any) => { setSpotlight(prevSpot => ({...prevSpot, active: !prevSpot.active}))}}></Switch>
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <InputLabel id="typeLabel">Type</InputLabel>
                            <Select labelId="typeLabel" id="typeSelect" name="type" label="Type" value={spotlight?.type} onChange={handleInputChange}>
                                <MenuItem value="events">Event</MenuItem>
                                <MenuItem value="restorations">Restoration</MenuItem>
                                <MenuItem value="toneteam">ToneTeam</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <InputLabel htmlFor="titleInput">Title</InputLabel>
                            <OutlinedInput id="titleInput" name="title" label="Title" value={spotlight?.title} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl fullWidth style={{paddingBottom: "10px"}}>
                            <InputLabel htmlFor="descriptionInput">Description</InputLabel>
                            <OutlinedInput multiline id="descriptionInput" name="description" label="Description" rows={6} value={spotlight?.description} onChange={handleInputChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={spotlight.id ? 6 : 12} style={spotlight.id ? {display: "block"} : {display: "none"}}>
                        <ImageUploader 
                            style={{display: (spotlight.id) ? "block" : "none"}}
                            images={spotlight.photos}
                            onDelete={deletePic} 
                            onReorder={reorderPic} 
                            filesPicked={filesPicked}></ImageUploader>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default AddEditGear;
