import { useContext, useEffect, useState } from "react";

function Privacy() {
  return (
    <div>
      <section className={"section-hero parallax--bg privacy"}>
          <div className="container">
              <h1 className="parallax--box">PRIVACY</h1>
              <p>YOUR PRIVACY IS IMPORTANT.</p>
          </div>
      </section>
      <div className="container2">
        <h2>PRIVACY POLICY</h2>
        <p>At CescosCornerGuitars, your privacy is important to us. This website is designed solely for users to browse our inventory, past inventory, personal collection, and services related to vintage guitar sales and restoration.</p>
        <p>We do not use cookies or collect any personal data while you visit our site. We do not track your browsing activities or store any information that could be used to identify you.</p>
        <p>Our website does not employ analytics tools or third-party services that gather data about visitors. Your browsing experience is entirely anonymous.</p>
        <p>If you have any questions about this privacy policy or our website, please feel free to contact us <a href="/contact">HERE</a>.</p>
      </div>
    </div>
  );
}

export default Privacy;