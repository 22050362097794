import { useContext, useEffect, useState } from "react";
import ContactForm from "../general/ConactForm";

function Appraisals() {
  return (
    <div>
      <section className={"section-hero parallax--bg appraisals"}>
          <div className="container">
              <h1 className="parallax--box">APPRAISALS</h1>
              <p>Know the real value of your precious and beloved treasures!</p>
          </div>
      </section>
      <div className="container2">
        <h2>GET THE MOST OF YOUR INSTRUMENTS</h2>
        <p>Our Appraisal service is offered only with a in-hand inspection, and a complete and detailed, hand signed and dated document will be released, with professional pictures attached, taken here at the same time of the inspection. If requested, we will insert your guitar in our Portfolio. In order to maintain the highest level possible for our Appraisal Service, we will never release one just basing on pictures you will send us via email, but we will gladly help you with your concerns regarding a certain instrument if you send us your inquiry. Just, a official signed Appraisal won&apos;t be released without our in-hand inspection.</p>
      </div>
      <ContactForm />
    </div>
  );
}

export default Appraisals;