import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Box, Tab, TableContainer, Tabs, Paper, Table, TableHead, TableRow, TableBody, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Gear from "../../models/gear";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HoverIcon from "../general/HoverIcon";
import AddIcon from "@mui/icons-material/Add";
import Spotlight from "../../models/spotlight";
import { error } from "console";
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

interface AdminProps {
    onWait: (wait: boolean) => void;
}

const Admin: React.FC<AdminProps> = ({onWait}) =>{
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [account, setAccount] = useState<AccountInfo>();
    const [gear, setGear] = useState<Gear[]>();
    const [spotlights, setSpotlights] = useState<Spotlight[]>();
    const [deleteObject, setDeleteObject] = useState<any>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    // useEffect to handle first load
    useEffect(() => {
        // start waiting
        onWait(true);
        const firstLoad = async function () {
            // Initialize MSAL
            await instance.initialize(); 
            await instance.handleRedirectPromise(); 
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                // no accounts in session...initiate a loginRedirect
                instance.loginRedirect({
                    scopes: ["User.Read"]
                });
            }
            else {
                let authResult:AuthenticationResult | null = null;
                try {
                    authResult = await instance.acquireTokenSilent({
                        account: accounts[0],
                        scopes: ["User.Read"]
                    });
                    setAccount(authResult.account);
                }
                catch {
                    // no accounts in session...initiate a loginRedirect
                    instance.loginRedirect({
                        scopes: ["User.Read"]
                    });
                }

                if (authResult) {
                    // authorize
                    const authResp = await fetch("/api/Authorize", { method: "POST", headers: {
                        "Authorization": `Bearer ${authResult.idToken}`,
                        "Content-Type": "application/json",
                    }});
                    if (!authResp.ok) {
                        navigate("/");
                    }

                    // get all gear
                    const gearResponse = await fetch("/api/GetGear", {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${authResult.idToken}`,
                            "Content-Type": "application/json",
                        },
                    });
                    if (!gearResponse.ok) {
                        navigate("/");
                    }
                    else {
                        const result = await gearResponse.json();
                        setGear(result);
                    }

                    // get all spotlights
                    const spotlightResponse = await fetch("/api/GetSpotlight", {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${authResult.idToken}`,
                            "Content-Type": "application/json",
                        },
                    });
                    if (!spotlightResponse.ok) {
                        navigate("/");
                    }
                    else {
                        const result = await spotlightResponse.json();
                        setSpotlights(result);
                        onWait(false);
                    }
                }
            }
        };
        firstLoad();
    }, []);

    const handleConfirmDelete = async () => {
        onWait(true);
        const type = (deleteObject.make) ? "gear" : "spotlight";
        var resp = await fetch(`/api/DeleteItem/${type}/${deleteObject.id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${account?.idToken}`
            }
        });
        
        // check for success
        if (resp.ok) {
            if (type === "gear")
                setGear(gear?.filter(i => i.id !== deleteObject.id));
            else
                setSpotlights(spotlights?.filter(i => i.id !== deleteObject.id));
        }
        setDeleteObject(null);
        onWait(false);
    }

    let inventory:any, portfolio:any, personal:any;
    if (gear) {
        inventory = gear.filter(i => i.collection === "inventory").map((item:Gear, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/${item.collection}/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/gear/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        portfolio = gear.filter(i => i.collection === "portfolio").map((item:Gear, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/${item.collection}/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/gear/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        personal = gear.filter(i => i.collection === "personal").map((item:Gear, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/${item.collection}/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/gear/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
    }

    let events:any, restorations:any, toneteam:any, blogs:any;
    if (spotlights) {
        { /*
        events = spotlights.filter(i => i.type === "events").map((item:Spotlight, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/events/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/spot/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        */}
        restorations = spotlights.filter(i => i.type === "restorations").map((item:Spotlight, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/restorations/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/spot/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        toneteam = spotlights.filter(i => i.type === "toneteam").map((item:Spotlight, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/toneteam/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/spot/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        blogs = spotlights.filter(i => i.type === "blog").map((item:Spotlight, index:number) => (
            <StyledTableRow key={item.path}>
                <StyledTableCell component="th" scope="row">
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <HoverIcon Icon={PreviewIcon} onClick={() => navigate(`/blog/${item.path}`)} />
                    <HoverIcon Icon={EditIcon} onClick={() => navigate(`/admin/blog/edit/${item.path}`)} />
                    <HoverIcon Icon={DeleteIcon} onClick={() => setDeleteObject(item)} />
                </StyledTableCell>
            </StyledTableRow>
        ));
    }

    const deleteConfirmDialog = (
        <Dialog open={deleteObject} onClose={() => setDeleteObject(null)} aria-labelledby="confirm-delete-dialog">
            <DialogTitle id="confirm-delete-dialog">Confirm Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Are you sure you want to delete this item? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteObject(null)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }
    
    
    // return the JSX
    return (
        <div>
            {deleteConfirmDialog}
            <section className={"section-hero parallax--bg"}>
                <div className="container">
                    <h1 className="parallax--box">Administration</h1>
                    <p>Manage gear, restorations, events, and more</p>
                </div>
            </section>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs variant="scrollable" scrollButtons="auto" value={selectedTabIndex} onChange={(event: React.SyntheticEvent, newValue: number) => {setSelectedTabIndex(newValue)}}>
                        <Tab label="Inventory" {...a11yProps(0)} />
                        <Tab label="Portfolio" {...a11yProps(1)} />
                        <Tab label="Personal Collection" {...a11yProps(2)} />
                        {/*<Tab label="Events" {...a11yProps(3)} />*/}
                        <Tab label="Restorations" {...a11yProps(3)} />
                        <Tab label="ToneTeam" {...a11yProps(4)} />
                        <Tab label="Blogs" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <div hidden={selectedTabIndex !== 0} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Inventory</h2>
                        <Button onClick={() => navigate("/admin/gear/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inventory}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div hidden={selectedTabIndex !== 1} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Portfolio</h2>
                        <Button onClick={() => navigate("/admin/gear/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {portfolio}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div hidden={selectedTabIndex !== 2} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Personal Collection</h2>
                        <Button onClick={() => navigate("/admin/gear/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {personal}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {/*
                <div hidden={selectedTabIndex !== 3} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Events</h2>
                        <Button onClick={() => navigate("/admin/spot/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                */}
                <div hidden={selectedTabIndex !== 3} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Restorations</h2>
                        <Button onClick={() => navigate("/admin/spot/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {restorations}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div hidden={selectedTabIndex !== 4} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>ToneTeam</h2>
                        <Button onClick={() => navigate("/admin/spot/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {toneteam}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div hidden={selectedTabIndex !== 5} style={{padding: "20px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h2 style={{ margin: "5px 0px" }}>Blogs</h2>
                        <Button onClick={() => navigate("/admin/blog/create")} variant="outlined" startIcon={<AddIcon />}>Create</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {blogs}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </div>
    );
}

export default Admin;
