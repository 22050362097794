import ContactForm from "../general/ConactForm";

function Contact() {
  return (
    <div>
      <section className={"section-hero parallax--bg contact"}>
          <div className="container">
              <h1 className="parallax--box">CONTACT</h1>
              <p>Give us a shout!</p>
          </div>
      </section>
      <div className="container2">
        <p>Feel free to contact us for any comment, sugestion, question or information about my site. We'll do our best to respond promptly. Thanks in advance for your patience in our reply.</p>
      </div>
      <ContactForm />
    </div>
  );
}

export default Contact;